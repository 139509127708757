import { tix, withProps } from "@/libs/tix";
import * as nui from "@nextui-org/react";

export const Button = withProps<nui.ButtonProps>(tix)(
  {
    name: "Button",
    variants: {},
  },
  nui.Button,
  (styled) => (props, ref) => {
    const [El, _props] = styled(props);

    // NextUI Button component does support `as` prop so we don't need to override it
    if (props.as) return <nui.Button as={props.as} {..._props} ref={ref} />;

    return <El {..._props} ref={ref} />;
  }
);
