import axios from "axios";

export const API_URL = process.env.API_URL || "http://localhost:3000";
// ? maybe changed for developement
export const downloadPassUrl = (serial: string) => `${API_URL}/passes/${serial}/get`;

export const client = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

// const wsPath = "/ws";
// export const ws = new WebSocket(`wss://${API_URL.replace("http://", "").replace("https://", "")}${wsPath}`);
