import { BrowserRouter } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { Provider as ReduxProvider } from "react-redux";

import { store } from "@/model/store";

import { AppRoutes } from "./AppRoutes";
import * as cps from "@/components";
import { Toaster } from "sonner";

export const App = () => {
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <NextUIProvider>
          <Toaster position="top-right" richColors closeButton />
          <cps.MyDialogProvider>
            <AppRoutes />
          </cps.MyDialogProvider>
        </NextUIProvider>
      </BrowserRouter>
    </ReduxProvider>
  );
};
