import React, { createContext, FC, useContext, useEffect, useRef, useState } from "react";
import { useDialog, useOverlay, usePreventScroll, useModal, FocusScope, OverlayContainer } from "react-aria";

import { useOverlayTriggerState, OverlayTriggerState } from "@react-stately/overlays";
import { AriaOverlayProps } from "@react-aria/overlays";
import { OverlayTriggerProps } from "@react-types/overlays";
import { AriaDialogProps } from "@react-types/dialog";

import { tix, tw, withProps } from "@/libs/tix";

interface DialogStates {
  [key: string]: (Omit<OverlayTriggerState, "open"> & { open: (props?: any) => void }) | undefined;
}

interface Register {
  (
    name: string,
    state: Omit<OverlayTriggerState, "open"> & {
      open: (props?: any) => void;
    }
  ): void;
}

interface UnRegister {
  (name: string): void;
}

export interface MyDialogContext {
  dialogs: DialogStates;
  register: Register;
  unregister: UnRegister;
}

export const MyDialogContext = createContext<MyDialogContext>({
  dialogs: {},
  register: () => {
    console.log("No MyDialogProvider provided!");
  },
  unregister: () => {
    console.log("No MyDialogProvider provided!");
  },
});

export const useMyDialogs = () => useContext(MyDialogContext);

export const MyDialogProvider: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const [dialogStates, setDialogs] = useState<DialogStates>({});

  const handleRegisterDialog: Register = (name, state) => {
    setDialogs((dialogs) => {
      return { ...dialogs, [name]: state };
    });
  };

  const handleUnRegisterDialog: UnRegister = (name) => {
    setDialogs((dialogs) => {
      return { ...dialogs, [name]: undefined };
    });
  };

  return (
    <MyDialogContext.Provider
      value={{
        dialogs: dialogStates,
        register: handleRegisterDialog,
        unregister: handleUnRegisterDialog,
      }}
    >
      {props.children}
    </MyDialogContext.Provider>
  );
};

export interface IMyDialogProps {
  name: string;
  title?: string;
  initial?: OverlayTriggerProps;
  className?: string;
  classNameOverlay?: string;
  render: (close: () => void, props: any) => React.ReactNode;
}

export interface IProps extends AriaOverlayProps, AriaDialogProps {
  children?: React.ReactNode;
  className?: string;
  classNameOverlay?: string;
}

export const ModalDialog: FC<IProps> = (props) => {
  const { children } = props;

  // Handle interacting outside the dialog and pressing
  // the Escape key to close the modal.
  const ref = useRef<HTMLDivElement>(null);
  const { overlayProps, underlayProps } = useOverlay(props, ref);

  // Prevent scrolling while the modal is open, and hide content
  // outside the modal from screen readers.
  usePreventScroll();
  const { modalProps } = useModal();

  // Get props for the dialog and its title
  // const { dialogProps, titleProps } = useDialog(props, ref);
  const { dialogProps } = useDialog(props, ref);

  return (
    <OverlayContainer>
      <DialogOverlay className={props.classNameOverlay} {...underlayProps}>
        <FocusScope contain>
          <DialogFocusContent className={props.className} {...overlayProps} {...dialogProps} {...modalProps} ref={ref}>
            {children}
          </DialogFocusContent>
        </FocusScope>
      </DialogOverlay>
    </OverlayContainer>
  );
};

export const Dialog = withProps<IMyDialogProps>(tix)(
  { name: "Dialog", variants: {} },
  ModalDialog,
  (styled) => (props, ref) => {
    const [El, _props] = styled(props);

    const state = useOverlayTriggerState(props.initial || {});
    const [openProps, setOpenProps] = useState<any>({});

    const _state = {
      ...state,
      open: (props: any) => {
        setOpenProps(props);
        state.open();
      },
    };

    const { register, unregister } = useMyDialogs();

    useEffect(() => {
      register(props.name, _state);
      return () => unregister(props.name);
    }, []);

    return state.isOpen ? (
      <El
        className={_props.className}
        title={props.title ? props.title : props.name}
        isOpen={state.isOpen}
        isDismissable
        shouldCloseOnInteractOutside={() => true}
        onClose={state.close}
        {...props}
      >
        {props.render(state.close, openProps)}
      </El>
    ) : (
      <></>
    );
  }
);

const DialogOverlay = tix(
  {
    name: "DialogOverlay",
    base: tw`fixed top-0 z-50 flex h-screen w-full justify-center overflow-auto bg-black bg-opacity-50`,
    variants: {},
  },
  "div"
);

const DialogFocusContent = tix(
  {
    name: "DialogFocusContent",
    base: tw`my-8 focus-visible:outline-none sm:my-16 h-fit`,
    variants: {},
  },
  "div"
);
