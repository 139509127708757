import { FaFileUpload } from "react-icons/fa";
import { tix, withProps } from "@/libs/tix";
import { Input } from "@nextui-org/react";
import { BaseSyntheticEvent } from "react";

export interface UploadFileProps {
  onUpload: (file: File) => void;
}

export const UploadFile = withProps<UploadFileProps>(tix)(
  {
    name: "UploadFile",
    base: "py-2",
    variants: {},
  },
  Input,
  (styled) => (props, ref) => {
    const [El, { onUpload, ..._props }] = styled(props);

    const onChange = (event: BaseSyntheticEvent) => {
      const file: File = event.target.files[0];
      if (file) {
        onUpload(file);
      }
    };

    return <El {..._props} ref={ref} type="file" onChange={onChange} endContent={<FaFileUpload />}></El>;
  }
);
