import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { typedName, dotPrefixer } from "@/libs/types";
import { jwtDecode } from "jwt-decode";

import { client } from "@/model/api";
import { rootSelector, createSelector, RootState } from "@/model/store";
import { createAsyncThunk } from "@/model/thunk";

export const name = typedName("accounts");
export const thunkName = dotPrefixer(name);
name as keyof RootState; // validation correctness setup reducers

const isExpired = () => {
  const token = localStorage.getItem("token");
  if (token == null) {
    return false;
  }

  const decodedJwt = jwtDecode(token);
  const expiredTime = decodedJwt["exp"] || 0;
  const current = new Date().getTime() / 1000;

  // * force expire if expiration time less than 1 day
  return (expiredTime - current) / (24 * 3600) <= 1;
};

/**
 * ACTION TYPES
 */
const DO_USER_LOGIN = typedName("doUserLogin");
const DO_USER_LOGOUT = typedName("doUserLogout");
const DO_GET_PROFILE = typedName("doGetProfile");

/**
 * MODEL TYPES
 */
export interface IAccount {
  ID: number;
  userName: string;
  email: string;
  mobile: string;
  password: string;
  type: string;
}

/**
 * ACTION Handlers
 */
export const actions = {
  [DO_USER_LOGIN]: createAsyncThunk(
    thunkName(DO_USER_LOGIN),
    async (
      payload: {
        username: string;
        password: string;
      },
      { dispatch, rejectWithValue }
    ) => {
      return client
        .post("/users/login", payload)
        .then((res) => {
          dispatch(setToken(res.data));
          dispatch(saveToken(res.data));
          return res.data;
        })
        .catch(rejectWithValue);
    }
  ),
  [DO_USER_LOGOUT]: createAsyncThunk(thunkName(DO_USER_LOGOUT), async (_payload: {}, { dispatch, rejectWithValue }) => {
    return Promise.resolve({ data: {} })
      .then(() => {
        dispatch(removeToken());
        window.location.replace("/login");
      })
      .catch(rejectWithValue);
  }),
  [DO_GET_PROFILE]: createAsyncThunk(thunkName(DO_GET_PROFILE), async (_payload: {}, { dispatch, rejectWithValue }) => {
    return client
      .get("/users/profile")
      .then((res) => {
        dispatch(setProfile(res.data));
        return res.data;
      })
      .catch(rejectWithValue);
  }),
};

/**
 * QUERY Selectors
 */
export const selectors = {
  isLogged: () =>
    createSelector(rootSelector, (state) => {
      return state.accounts.token !== null;
    }),
  isExpired: () =>
    createSelector(rootSelector, () => {
      return isExpired();
    }),
};

interface IState {
  token: string | null;
  profile: IAccount | null;
}

export const preloadedState: IState = {
  token: localStorage.getItem("token") || null,
  profile: null,
};

export const slice = createSlice({
  name: name,
  initialState: {
    token: null,
    profile: null,
  } as IState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    saveToken: (_state, action: PayloadAction<string>) => {
      localStorage.setItem("token", action.payload);
    },
    removeToken: (state) => {
      state.token = null;
      localStorage.removeItem("token");
    },
    setProfile: (_state, action: PayloadAction<IAccount>) => {
      _state.profile = action.payload;
    },
  },
});

export const { setToken, saveToken, removeToken, setProfile } = slice.actions;

export const mutates = slice.actions;
