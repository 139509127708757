import { useEffect, useMemo, useState } from "react";
import { groupBy, sortBy } from "remeda";
import { tix, tw } from "@/libs/tix";

import { useDispatch, useSelector } from "@/model/store";
// import { actions, selectors } from "@/model/slices/templates";
import * as eventlogs from "@/model/slices/eventlogs";
import * as passes from "@/model/slices/passes";
import * as messages from "@/model/slices/messages";

// import * as cps from "@/components";
// import * as bls from "@/components/blocks";

import {
  getKeyValue,
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";
import { fmtDateTime } from "@/libs/format";

const TemplateColumns = [
  {
    key: "ID",
    label: "ID",
    sort: true,
  },
  {
    key: "content",
    label: "Message Content",
  },
  {
    key: "scheduleAt",
    label: "Schedule Time",
  },
  {
    key: "firstSent",
    label: "First Sent",
  },
  {
    key: "lastSent",
    label: "Last Sent",
  },
  {
    key: "sent",
    label: "Sent",
  },
  {
    key: "totalPasses",
    label: "Added / Total Passes",
  },
];

export const Messages = tix(
  {
    name: "PageMessages",
    base: tw`py-8`,
    variants: {},
  },
  "div",
  (styled) => (props, ref) => {
    const dispatch = useDispatch();
    // const templates = useSelector(selectors.templates());

    const allMessageObjs = useSelector(messages.selectors.all());
    const allEventLogs = useSelector(eventlogs.selectors.all());
    const allPasses = useSelector(passes.selectors.passes());
    const allPassWithLogs = allPasses.map((pass) => {
      const eventLog = allEventLogs.toReversed().find((eventLog) => eventLog.serial === pass.serial);
      return {
        ...pass,
        lastEventLog: eventLog,
      };
    });

    useEffect(() => {
      // dispatch(actions.listTemplates({}));
      dispatch(eventlogs.actions.doGetEventlogs({}));
      dispatch(passes.actions.listPasses({}));
      dispatch(messages.actions.doGetMessageObjects({}));
    }, []);

    const passesByTmpl = groupBy(allPassWithLogs, (pass) => pass.passTemplateID);

    const totalPasses = (templateId: number) => {
      const passes = passesByTmpl[templateId] || [];
      const added = passes.filter(
        (pass) => pass.lastEventLog?.type === "add" || pass.lastEventLog?.type === "refresh"
      ).length;
      return `${added} / ${passes.length}`;
    };

    const [sorting, setSorting] = useState<SortDescriptor | null>(null);

    const rows = useMemo(() => {
      return allMessageObjs.map((item) => {
        const [first, ...tail] = sortBy(item.messages, (msg) => new Date(msg.createdAt));
        const last = tail.pop();

        return {
          ID: item.ID,
          content: item.content,
          scheduleAt: item.scheduleAt ? fmtDateTime(item.scheduleAt) : "",
          firstSent: first ? fmtDateTime(first.createdAt) : "",
          lastSent: last ? fmtDateTime(last.createdAt) : "",
          sent: item.messages.length,
          totalPasses: totalPasses(item.passTemplateID),
        };
      });
    }, [allMessageObjs]);

    const doSort = (descriptor: SortDescriptor) => {
      setSorting(descriptor);
      return {
        items: rows.sort((firstItem, secondItem) => {
          if (!descriptor?.column) {
            return 0;
          }
          const first = getKeyValue(firstItem, descriptor.column);
          const second = getKeyValue(secondItem, descriptor.column);
          let cmp = (parseInt(first) || first) < (parseInt(second) || second) ? -1 : 1;
          if (descriptor.direction === "descending") {
            cmp *= -1;
          }

          return cmp;
        }),
      };
    };

    const [El, _props] = styled(props);
    return (
      <El {..._props} ref={ref}>
        <div>
          <div className="flex justify-between">
            <h1 className="text-2xl text-primary-300 font-semibold">Message Objects</h1>
          </div>
          <div className="mt-5">
            <Table
              aria-label="Message Objects"
              isStriped
              topContentPlacement="outside"
              bottomContentPlacement="outside"
              sortDescriptor={sorting || undefined}
              onSortChange={doSort}
            >
              <TableHeader columns={TemplateColumns}>
                {(col) => (
                  <TableColumn aria-label={col.key} key={col.key} allowsSorting={col.sort} className="text-small">
                    {col.label}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody items={rows} emptyContent={"No rows to display."}>
                {(row) => (
                  <TableRow className="h-10" key={row.ID}>
                    {(colKey) => <TableCell>{getKeyValue(row, colKey)}</TableCell>}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </El>
    );
  }
);
