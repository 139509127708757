import { tix, tw } from "@/libs/tix";

import * as cps from "@/components";

export const FieldSearch = tix(
  {
    name: "FieldSearch",
    base: tw`flex gap-x-2`,
    variants: {},
  },
  "div",
  (styled) => (props, ref) => {
    const [El, _props] = styled(props);

    return (
      <El {..._props} ref={ref}>
        <cps.Input placeholder="Enter search value and submit" />
        <cps.Button>Search</cps.Button>
      </El>
    );
  }
);
