import { tix, tw, withProps } from "@/libs/tix";
import { useState, useEffect } from "react";
import { useDebouncedValue } from "@/libs/debounce";
import { Input } from "@nextui-org/react";

export interface DebouceInputProps {
  value?: string;
  delay?: number;
  onValueChange?: (val: string) => void;
}

export const DebouceInput = withProps<DebouceInputProps>(tix)(
  {
    name: "DebouceInput",
    base: tw`w-full`,
    variants: {},
  },
  Input,
  (styled) => (props, ref) => {
    const { value, delay = 500, onValueChange } = props;
    const [_val, _setVal] = useState(value || "");
    const debouncedValue = useDebouncedValue(_val, delay);

    useEffect(() => {
      onValueChange?.(_val);
    }, [debouncedValue]);

    useEffect(() => {
      _setVal(value || "");
    }, [value]);

    const [El, _props] = styled(props);
    return <El ref={ref} {...props} value={_val} onValueChange={(val) => _setVal(val)} />;
  }
);
