import {
  Button,
  ColorPicker,
  ColorArea,
  ColorSlider,
  Dialog,
  ColorField,
  ColorSwatch,
  DialogTrigger,
  Popover,
  Label,
  Input,
  ColorThumb,
  SliderOutput,
  SliderTrack,
  Color,
  parseColor,
} from "react-aria-components";
import "./ColorPicker.css";
import { useEffect, useState } from "react";
import { useDebouncedValue } from "@/libs/debounce";

export interface InputColorPickerProps {
  onColorChange: (color: Color) => void;
  defaultColor?: string;
}

export const ColorPickerDialog = ({ onColorChange, defaultColor }: InputColorPickerProps) => {
  const [_color, _setColor] = useState(defaultColor || "#FFFFFF");
  const debouncedValue = useDebouncedValue(_color, 300);

  useEffect(() => {
    _setColor(defaultColor || "#FFFFFF");
  }, [defaultColor]);

  useEffect(() => {
    onColorChange?.(parseColor(_color));
  }, [debouncedValue]);

  return (
    <ColorPicker onChange={(val) => _setColor(val.toString("hex"))}>
      <DialogTrigger>
        <Button className="color-picker h-full p-0 border-none items-center outline-none appearance-none align-middle">
          <div className="rounded-full aspect-square h-full border-solid border-default-800 border-small cursor-pointer">
            <ColorSwatch className="h-full rounded-full" color={_color} />
          </div>
        </Button>

        <Popover placement="bottom start">
          <Dialog
            className="color-picker-dialog flex flex-col gap-3 p-3 bg-background text-foreground rounded-medium border-small"
            aria-label="Color Picker"
          >
            <ColorArea className={"h-28"} colorSpace="hsb" xChannel="saturation" yChannel="brightness">
              <ColorThumb className="w-4 h-4 border-medium rounded-full" />
            </ColorArea>
            <ColorSlider channel="hue" colorSpace="hsb">
              <div className="flex flex-row justify-between text-tiny">
                <Label />
                <SliderOutput />
              </div>
              <SliderTrack className={"h-5"}>
                <ColorThumb />
              </SliderTrack>
            </ColorSlider>
            <ColorField
              aria-label="Color Text Field"
              className="text-medium pt-2"
              // onChange={(val) => setColorVal(val ? val : "#FFFFFF")}
            >
              <Input />
            </ColorField>
          </Dialog>
        </Popover>
      </DialogTrigger>
    </ColorPicker>
  );
};
