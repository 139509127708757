import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "@/model/store";
import { actions, selectors } from "@/model/slices/accounts";

import * as cps from "@/components";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "sonner";

interface ErrorState {
  [key: string]: string;
}

export const Login: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<ErrorState>({});
  const [isSuccess, setIsSuccess] = useState(false);
  const [isInvalidUserName, setIsInvalidUserName] = useState(false);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);

  const nav = useNavigate();
  const token = useSelector((state) => state.accounts.token);
  const isExpired = useSelector(selectors.isExpired());
  useEffect(() => {
    if (isExpired) {
      dispatch(actions.doUserLogout({}));
    }
    token ? nav("/") : null;
  }, []);

  useEffect(() => {
    const error: ErrorState = {};
    if (!username || typeof username === undefined || username === "") {
      setIsInvalidUserName(true);
      setIsSuccess(false);
      error.username = "Please enter your username.";
    } else {
      setIsInvalidUserName(false);
    }

    if (!password || typeof password === undefined || password === "") {
      setIsInvalidPassword(true);
      setIsSuccess(false);
      error.password = "Please enter your password.";
    } else {
      setIsInvalidPassword(false);
    }

    if (username !== "" && password !== "") {
      setIsInvalidUserName(false);
      setIsInvalidPassword(false);
      setIsSuccess(true);
    }

    setError({ ...error });
  }, [username, password]);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleLogin = () => {
    if (!isInvalidUserName && !isInvalidPassword && isSuccess) {
      dispatch(
        actions.doUserLogin({
          username: username,
          password: password,
        })
      )
        .unwrap()
        .then((_) => {
          toast.success("Login successful");
          navigate("/");
        })
        .catch((err) => toast.error(`Login failed ${err.message}`));
    }
  };

  return (
    <section className="flex justify-center items-center min-h-screen px-4 m-auto xl:px-0">
      <cps.Card className="w-full md:w-[400px]">
        <cps.CardHeader>
          <h2 className="text-2xl font-semibold">Login</h2>
        </cps.CardHeader>
        <cps.CardBody>
          <div className="mb-4">
            <cps.Input
              name="username"
              aria-label="input-username"
              isClearable
              isRequired
              autoFocus
              type="text"
              label="Username"
              variant="bordered"
              placeholder="Enter your username"
              className="max-w-md"
              value={username}
              isInvalid={isInvalidUserName}
              errorMessage={error.username}
              onValueChange={setUsername}
            />
          </div>
          <div className="mb-6">
            <cps.Input
              name="password"
              aria-label="input-password"
              isRequired
              label="Password"
              variant="bordered"
              placeholder="Enter your password"
              endContent={
                <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                  {isVisible ? (
                    <FaEye className="text-xl text-default-400 pointer-events-none" />
                  ) : (
                    <FaEyeSlash className="text-xl text-default-400 pointer-events-none" />
                  )}
                </button>
              }
              type={isVisible ? "text" : "password"}
              className="max-w-md"
              value={password}
              isInvalid={isInvalidPassword}
              errorMessage={error.password}
              onValueChange={setPassword}
              onKeyDown={(e) => e.key === "Enter" && handleLogin()}
            />
          </div>
          <div className="flex items-center justify-between">
            <cps.Button color="primary" variant="solid" className="w-full" onClick={handleLogin}>
              Sign In
            </cps.Button>
          </div>
        </cps.CardBody>
      </cps.Card>
    </section>
  );
};
