import { tix, tw, withProps } from "@/libs/tix";
import { FaRegTrashCan, FaRegSquarePlus } from "react-icons/fa6";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "../Button";
import {
  FieldPosition,
  ITemplate,
  ITemplateCreate,
  ITemplateField,
  actions,
  getEditableFields,
  selectors,
} from "@/model/slices/templates";
import { actions as tagsAction, selectors as tagsSelectors } from "@/model/slices/tags";
import { InputColorPicker } from "../blocks";
import { useDispatch, useSelector } from "@/model/store";
import { toast } from "sonner";
import { DebouceInput } from "../DebouceInput";
import {
  Accordion,
  AccordionItem,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Select,
  SelectItem,
  Spacer,
  Tabs,
  Tab,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { UploadFile } from "../blocks/UploadFile";
import { Input } from "../Input";

export interface TemplateDialogProps {
  close: () => void;
  templateId?: number;
}

export const TemplateDialog = withProps<TemplateDialogProps>(tix)(
  {
    name: "TemplateDialog",
    base: tw`w-screen md:w-[600px] xl:w-[800px]`,
    variants: {},
  },
  "div",
  (styled) =>
    ({ templateId, ...props }, ref) => {
      const dispath = useDispatch();
      const tags = useSelector(tagsSelectors.tags());
      const definedTemplates = useSelector(selectors.definedTemplates());
      const [template, setTemplate] = useState<ITemplate>({
        ID: 0,
        description: "",
        orgName: "",
        style: "generic",
        foregroundColor: "",
        backgroundColor: "",
        labelColor: "",
        qrCodeValue: "",
        qrCodeType: "",
        serial: "",
        fields: [],
        tagIDs: [],
        tags: [],
      });
      const [files, setFiles] = useState<{ [key: string]: File }>({});
      const [isLoading, setLoading] = useState(false);

      useEffect(() => {
        // * List avaiable tags
        dispath(tagsAction.listTags());

        // * Fetch latest template's info by ID
        if (templateId) {
          dispath(actions.getTemplateById(templateId))
            .unwrap()
            .then((data) => {
              setTemplate(data);
            });
        }
      }, [templateId]);

      const onSubmit = () => {
        if (templateId) {
          onSubmitUpdate();
        } else {
          onSubmitCreate();
        }
      };

      const onSubmitCreate = () => {
        const { ID, serial, ..._temp } = template;
        const templateCreate: ITemplateCreate = {
          ..._temp,
        };
        setLoading(true);
        dispath(actions.createTemplate(templateCreate))
          .unwrap()
          .then(async (newTemplate: ITemplate) => {
            if ((await onSubmitUpload(newTemplate.ID)) === true) {
              toast.success("Template created successfully");
              props.close();
            }
          })
          .catch((err) => {
            toast.error("Failed to create template", err);
          })
          .finally(() => {
            setLoading(false);
          });
      };

      const onSubmitUpdate = async () => {
        setLoading(true);
        dispath(actions.updateTemplate({ ...template }))
          .unwrap()
          .then(async () => {
            if ((await onSubmitUpload()) === true) {
              toast.success("Template updated successfully");
              props.close();
            }
          })
          .catch((err) => {
            toast.error("Failed to update template", err);
          })
          .finally(() => {
            setLoading(false);
          });
      };

      const onSubmitUpload = async (templateId: number = template.ID) => {
        if (Object.keys(files).length === 0) {
          return true;
        }

        const formData = new FormData();
        for (const [fileName, file] of Object.entries(files)) {
          formData.append(fileName, file, file.name);
        }
        try {
          await dispath(actions.uploadTemplateFile({ templateId: templateId, data: formData })).unwrap();
          return true;
        } catch (err: any) {
          toast.error("Failed to upload template files", err);
          return false;
        }
      };

      const onTemplateValChange = useCallback(
        (key: keyof ITemplate, val: string) => {
          setTemplate({ ...template, [key]: val });
        },
        [template]
      );

      const onFieldChange = useCallback(
        (fieldIndex: number, newField: ITemplateField) => {
          const _fields = [...template.fields];
          _fields[fieldIndex] = newField;
          setTemplate({ ...template, fields: _fields });
        },
        [template]
      );

      const onUploadImage = (fileName: string, file: File) => {
        setFiles({
          ...files,
          [fileName]: file,
        });
      };

      const tagsSelection = useMemo(() => {
        return (
          <Dropdown>
            <DropdownTrigger>
              <Input label="Tags" value={`${template.tags}`} classNames={{ input: tw`text-left` }} />
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Multiple selection example"
              variant="solid"
              closeOnSelect={false}
              disallowEmptySelection
              selectionMode="multiple"
              selectedKeys={template.tagIDs.map((id) => id.toString())}
              onSelectionChange={(keys) => {
                const tagIds = Array.from(keys).map((key) => parseInt(key.toString()));
                const tagName = tags.filter((tag) => tagIds.includes(tag.ID)).map((tag) => tag.tag);
                setTemplate({ ...template, tagIDs: tagIds, tags: tagName });
              }}
            >
              {tags.map((tag) => (
                <DropdownItem key={tag.ID}>{tag.tag}</DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        );
      }, [tags, template.tagIDs, template.tags]);

      const definedTemplateSelection = useMemo(() => {
        return definedTemplates.map((tmp, i) => {
          return (
            <div
              className="max-w-60 max-h-96 h-96 inline-block border-medium border-primary-200 cursor-pointer hover:border-primary-400 mr-5 rounded-medium"
              key={i}
              onClick={() =>
                setTemplate((preTmp) => {
                  return { ...tmp, ID: preTmp.ID, serial: preTmp.serial, tags: [] };
                })
              }
            >
              <Card shadow="none" className="h-full">
                <CardHeader className="text-primary-600 text-large">{tmp.description}</CardHeader>
                <CardBody className="flex flex-col justify-between">
                  <div>
                    <p className="text-gray-500">{tmp.orgName}</p>
                  </div>
                  <div>
                    <img src={tmp.previewImg} alt="" />
                  </div>
                </CardBody>
              </Card>
            </div>
          );
        });
      }, [definedTemplates]);

      const [El, { close, ..._props }] = styled(props);
      return (
        <El {..._props} ref={ref}>
          <Card radius="sm">
            <CardHeader className="text-primary-600 text-large">Create Template</CardHeader>
            <Divider />
            <CardBody>
              <Tabs>
                <Tab title="Template Info">
                  {!templateId ? (
                    <div className="mt-2">
                      <Accordion className="px-0">
                        <AccordionItem
                          title="Select our defined templates"
                          classNames={{ trigger: "py-2", content: "overflow-x-hidden" }}
                        >
                          <div className="gap-4 overflow-x-scroll overflow-y-hidden whitespace-nowrap">
                            {definedTemplateSelection}
                          </div>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  ) : null}
                  <div className="grid grid-cols-2 gap-4 mt-4">
                    <DebouceInput
                      delay={600}
                      size="sm"
                      radius="sm"
                      label="Description"
                      isRequired
                      value={template.description}
                      onValueChange={(val) => onTemplateValChange("description", val)}
                    />
                    <DebouceInput
                      delay={600}
                      size="sm"
                      radius="sm"
                      label="Org. Name"
                      isRequired
                      value={template.orgName}
                      onValueChange={(val) => onTemplateValChange("orgName", val)}
                    />
                    <DebouceInput
                      delay={600}
                      size="sm"
                      radius="sm"
                      label="Pass Style"
                      value="Generic"
                      isRequired
                      isReadOnly
                    />
                    <InputColorPicker
                      label="Foreground Color"
                      className="h-12"
                      value={template.foregroundColor}
                      onColorChange={(val) => onTemplateValChange("foregroundColor", val)}
                    />
                    <InputColorPicker
                      label="Background Color"
                      className="h-12"
                      value={template.backgroundColor}
                      onColorChange={(val) => onTemplateValChange("backgroundColor", val)}
                    />
                    <InputColorPicker
                      label="Label Color"
                      className="h-12"
                      value={template.labelColor}
                      onColorChange={(val) => onTemplateValChange("labelColor", val)}
                    />
                    <DebouceInput
                      delay={600}
                      size="sm"
                      radius="sm"
                      label="QR Code Value"
                      value={template.qrCodeValue}
                      onValueChange={(val) => onTemplateValChange("qrCodeValue", val)}
                    />
                    <Select
                      size="sm"
                      radius="sm"
                      label="QR Code Type"
                      selectedKeys={[template.qrCodeType]}
                      onChange={(val) => onTemplateValChange("qrCodeType", val.target.value)}
                      isRequired
                    >
                      <SelectItem key="QR">QR</SelectItem>
                      <SelectItem key="CODE128">Barcode</SelectItem>
                    </Select>
                    <div>{tagsSelection}</div>
                  </div>
                  <Accordion className="px-0">
                    {getEditableFields(template).map((field, index) => (
                      <AccordionItem
                        key={index}
                        aria-label="Field"
                        title={`#${index + 1}. ${field.key || "Field"}`}
                        classNames={{ base: tw`mt-2`, trigger: tw`py-3` }}
                      >
                        <div key={index} className="grid grid-cols-2 gap-4 mt-2">
                          <DebouceInput
                            delay={600}
                            size="sm"
                            radius="sm"
                            label="Key"
                            aria-label="field-key"
                            isRequired
                            value={field.key}
                            onValueChange={(val) => onFieldChange(index, { ...field, key: val })}
                          />
                          <Select
                            size="sm"
                            radius="sm"
                            label="Position"
                            defaultSelectedKeys={[field.position.toUpperCase()]}
                            onChange={(val) =>
                              onFieldChange(index, { ...field, position: val.target.value as FieldPosition })
                            }
                            isRequired
                          >
                            {Object.keys(FieldPosition).map((pos) => (
                              <SelectItem key={pos} value={pos}>
                                {pos}
                              </SelectItem>
                            ))}
                          </Select>
                          <DebouceInput
                            delay={600}
                            size="sm"
                            radius="sm"
                            label="Label"
                            aria-label="field-label"
                            value={field.languages.vi!.label}
                            isRequired
                            onValueChange={(val) =>
                              onFieldChange(index, {
                                ...field,
                                languages: {
                                  vi: {
                                    ...field.languages.vi!, // ! hardcode vi,
                                    label: val,
                                  },
                                },
                              })
                            }
                          />
                          <DebouceInput
                            delay={600}
                            size="sm"
                            radius="sm"
                            label="Value"
                            aria-label="field-value"
                            value={field.languages.vi!.value}
                            isRequired
                            onValueChange={(val) =>
                              onFieldChange(index, {
                                ...field,
                                languages: {
                                  vi: {
                                    ...field.languages.vi!, // ! hardcode vi,
                                    value: val,
                                  },
                                },
                              })
                            }
                          />
                          <Spacer />
                          <div>
                            <Button
                              variant="light"
                              className="float-right items-center justify-center text-danger-500"
                              endContent={<FaRegTrashCan />}
                              onClick={() => {
                                template.fields.splice(index, 1);
                                setTemplate({ ...template });
                              }}
                            >
                              Delete Field
                            </Button>
                          </div>
                        </div>
                      </AccordionItem>
                    ))}
                  </Accordion>
                  <Divider className="my-2" />
                  <div className="py-2">
                    <Button
                      variant="light"
                      className="float-right items-center justify-center text-primary-500"
                      endContent={<FaRegSquarePlus />}
                      onClick={() => {
                        template.fields.push({
                          key: `New field #${template.fields.length + 1}`,
                          position: "TITLE" as FieldPosition,
                          languages: { vi: { label: "New Field", value: "new Field", parsedParams: [] } },
                        });
                        setTemplate({ ...template });
                      }}
                    >
                      Add Field
                    </Button>
                  </div>
                </Tab>
                <Tab title="Images">
                  <div className="mt-4">
                    <h2 className="text-large">Your Passes must have images...</h2>
                    <div className="mt-4 grid grid-rows-2 md:grid-cols-2 gap-4">
                      <UploadFile label="Icon (Apple)" isRequired onUpload={(file) => onUploadImage("icon", file)} />
                      <UploadFile label="Logo" isRequired onUpload={(file) => onUploadImage("logo", file)} />
                      <UploadFile label="Thumbnail" isRequired onUpload={(file) => onUploadImage("thumbnail", file)} />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </CardBody>
            <Divider />
            <CardFooter className="flex justify-end">
              <Button color="primary" isLoading={isLoading} onClick={onSubmit}>
                {templateId ? "Update" : "Create"}
              </Button>
            </CardFooter>
          </Card>
        </El>
      );
    }
);
