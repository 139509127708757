import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { typedName, dotPrefixer } from "@/libs/types";

// import { client } from "@/model/api";
import { rootSelector, createSelector, RootState } from "@/model/store";
import { createAsyncThunk } from "@/model/thunk";
import { client } from "@/model/api";

export const name = typedName("eventlogs");
export const thunkName = dotPrefixer(name);
([] as (keyof RootState)[]).indexOf(name); // validation correctness setup reducers

/**
 * ACTION TYPES
 */
const DO_GET_EVENTLOGS = typedName("doGetEventlogs");

/**
 * MODEL TYPES
 */
interface EventLog {
  ID: number;
  platform: string;
  serial: string;
  type: string;
  createdAt: string;
}

/**
 * ACTION Handlers
 */
export const actions = {
  [DO_GET_EVENTLOGS]: createAsyncThunk(
    thunkName(DO_GET_EVENTLOGS),
    async (payload: {}, { dispatch, rejectWithValue }) => {
      return client
        .get<{ data: EventLog[] }>("/eventlogs")
        .then((res) => {
          dispatch(mutates.setAll(res.data.data));
          return res;
        })
        .catch(rejectWithValue);
    }
  ),
};

/**
 * QUERY Selectors
 */
export const selectors = {
  all: () =>
    createSelector(rootSelector, (state) => {
      return state.eventlogs.all;
    }),
};

export const slice = createSlice({
  name: name,
  initialState: {
    all: [] as EventLog[],
  },
  reducers: {
    setAll: (state, action: PayloadAction<EventLog[]>) => {
      state.all = action.payload;
    },
  },
});

export const mutates = slice.actions;
