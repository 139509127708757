import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { typedName, dotPrefixer } from "@/libs/types";

// import { client } from "@/model/api";
import { rootSelector, RootState } from "@/model/store";
import { createAsyncThunk } from "@/model/thunk";
import { client } from "../api";

export const name = typedName("slice/passes");
export const thunkName = dotPrefixer(name);
name as keyof RootState; // validation correctness setup reducers

/**
 * ACTION TYPES
 */
const LIST_PASSES = typedName("listPasses");
const GET_PASS = typedName("getPass");
const CREATE_PASS = typedName("createPass");

/**
 * MODEL TYPES
 */
export interface IPass {
  ID: number;
  paramValues: Record<string, string | number>;
  passTemplateID: number;
  serial: string;
  tagIDs: number[];
  tags: string[];
}
export interface IPassCreate extends Omit<IPass, "ID" | "serial" | "tagIDs" | "tags"> {}

/**
 * ACTION Handlers
 */
export const actions = {
  [LIST_PASSES]: createAsyncThunk(
    thunkName(LIST_PASSES),
    async (payload: { templateId?: number; sort?: string }, { dispatch, rejectWithValue }) => {
      let params = {}; // * query params
      if (payload.sort) {
        params = { sort: payload.sort };
      }
      return client
        .get<{ data: IPass[] }>("/passes", { params: params })
        .then((res) => {
          dispatch(setPasses(res.data.data));
          return res;
        })
        .catch(rejectWithValue);
    }
  ),
  [GET_PASS]: createAsyncThunk(
    thunkName(GET_PASS),
    async (payload: { id: number; serial: string }, { rejectWithValue }) => {
      return client
        .get<{ data: IPass }>(`/passes/${payload.id}?serial=${payload.serial}`)
        .then((res) => {
          return res.data.data;
        })
        .catch(rejectWithValue);
    }
  ),
  [CREATE_PASS]: createAsyncThunk(thunkName(CREATE_PASS), async (payload: IPassCreate, { rejectWithValue }) => {
    return client
      .post<{ data: IPass }>("/passes", payload)
      .then((res) => {
        return res.data.data;
      })
      .catch(rejectWithValue);
  }),
};

/**
 * QUERY Selectors
 */
export const selectors = {
  passes: () =>
    createSelector(rootSelector, (state) => {
      return state["slice/passes"].passes;
    }),
  pass: (id: number) =>
    createSelector(rootSelector, (state) => {
      return state["slice/passes"].passes.find((pass) => pass.ID === id) || null;
    }),
};

export const slice = createSlice({
  name: name,
  initialState: {
    passes: [] as IPass[],
    pass: {} as IPass,
  },
  reducers: {
    setPasses: (state, action: PayloadAction<IPass[]>) => {
      state.passes = action.payload;
    },
  },
});

export const { setPasses } = slice.actions;

export const mutates = slice.actions;
