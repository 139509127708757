import { tix, withProps } from "@/libs/tix";
import * as nui from "@nextui-org/react";

export const Input = withProps<nui.InputProps>(tix)(
  {
    name: "Input",
    variants: {},
  },
  nui.Input,
  (styled) => (props, ref) => {
    const [El, _props] = styled(props);

    if (props.as) return <nui.Input as={props.as} {..._props} ref={ref} />;

    return <El {..._props} ref={ref} />;
  }
);
