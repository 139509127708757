import { FaPlus } from "react-icons/fa6";
import { now, getLocalTimeZone } from "@internationalized/date";
import { tix, withProps } from "@/libs/tix";
import { Card, CardBody, CardFooter, CardHeader, Chip, DatePicker, DateValue, Divider } from "@nextui-org/react";
import { Input } from "../Input";
import { Button } from "../Button";
import { useEffect, useState } from "react";
import { useDispatch } from "@/model/store";
import { actions, ITemplate } from "@/model/slices/templates";
import { toast } from "sonner";

export interface MessageDialogProps {
  templateId: number;
  close: () => void;
}

export const MessageDialog = withProps<MessageDialogProps>(tix)(
  {
    name: "MessageDialog",
    base: "w-screen md:w-[600px] xl:w-[800px]",
    variants: {},
  },
  "div",
  (styled) => (props, ref) => {
    const dispatch = useDispatch();
    const [template, setTemplate] = useState<ITemplate>({
      ID: 0,
      description: "",
      orgName: "",
      style: "generic",
      foregroundColor: "",
      backgroundColor: "",
      labelColor: "",
      qrCodeValue: "",
      qrCodeType: "",
      serial: "",
      fields: [],
      tagIDs: [],
      tags: [],
    });
    const [scheduleDate, setScheduleDate] = useState<DateValue | null>(now(getLocalTimeZone()) as unknown as DateValue);
    const [content, setContent] = useState<string>("");
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
      dispatch(actions.getTemplateById(props.templateId))
        .unwrap()
        .then((data) => {
          setTemplate(data);
        });
    }, []);

    const submitCreateMessage = () => {
      setLoading(true);
      const _scheduleDate = scheduleDate?.toDate("UTC") || null;
      dispatch(
        actions.createMessage({
          content,
          scheduleAt: _scheduleDate,
          passTemplateID: props.templateId,
          passIDS: [],
        })
      )
        .unwrap()
        .then(() => {
          toast.success("Message created successfully");
          props.close();
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const [El, { templateId, close, ..._props }] = styled(props);
    return (
      <El {..._props} ref={ref}>
        <Card radius="sm">
          <CardHeader>Push Message</CardHeader>
          <Divider />
          <CardBody>
            <div className="gap-4 max-w-full">
              <div className="text-large">Click the badge you want to use as dynamic content in your message</div>
              {template.fields.map((field, i) => (
                <Chip
                  className="mt-4 mr-4 pr-2 bg-secondary-300 hover:cursor-pointer hover:bg-secondary-400"
                  onClick={() => setContent(`${content} [[ .${field.key} ]]`)}
                  key={i}
                  endContent={<FaPlus />}
                >
                  {field.languages["vi"]?.label}
                </Chip>
              ))}
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <Input label="Message" value={content} onValueChange={setContent} />
              <DatePicker
                label="Schedule Date"
                // hideTimeZone
                aria-label="Schedule Date"
                hourCycle={24}
                value={scheduleDate}
                granularity="minute"
                onChange={setScheduleDate}
                // endContent={<CiCircleRemove size={20} onClick={() => setScheduleDate(null)} />}
              />
            </div>
          </CardBody>
          <Divider />
          <CardFooter className="flex justify-end">
            <Button color="primary" onClick={submitCreateMessage} isLoading={isLoading}>
              Submit
            </Button>
          </CardFooter>
        </Card>
      </El>
    );
  }
);
