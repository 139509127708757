import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { typedName, dotPrefixer } from "@/libs/types";

// import { client } from "@/model/api";
import { rootSelector, createSelector, RootState } from "@/model/store";
import { createAsyncThunk } from "@/model/thunk";
import { client } from "@/model/api";

export const name = typedName("_CHANGE_ME");
export const thunkName = dotPrefixer(name);
([] as (keyof RootState)[]).indexOf(name); // validation correctness setup reducers

/**
 * ACTION TYPES
 */
const DO_CHANGE_ME = typedName("doChangeMe");

/**
 * MODEL TYPES
 */
interface ChangeMe {
  id: number;
}

/**
 * ACTION Handlers
 */
export const actions = {
  [DO_CHANGE_ME]: createAsyncThunk(thunkName(DO_CHANGE_ME), async (payload: {}, { dispatch, rejectWithValue }) => {
    // return client.get<{ items: ChangeMe[] }>("/api/change_me")
    return Promise.resolve({ data: { items: [] as ChangeMe[] } })
      .then((res) => {
        dispatch(setChangeMes(res.data.items));
        return res;
      })
      .catch(rejectWithValue);
  }),
};

/**
 * QUERY Selectors
 */
export const selectors = {
  byId: (id: number) =>
    createSelector(rootSelector, (state) => {
      return state._CHANGE_ME.items.find((item) => item.id === id);
    }),
};

export const slice = createSlice({
  name: name,
  initialState: {
    items: [] as ChangeMe[],
  },
  reducers: {
    setChangeMes: (state, action: PayloadAction<ChangeMe[]>) => {
      state.items = action.payload;
    },
  },
});

export const { setChangeMes } = slice.actions;

export const mutates = slice.actions;
