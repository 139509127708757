import { Outlet, useNavigate } from "react-router-dom";

import { tix, tw } from "@/libs/tix";
import { Header } from "@/components/blocks/Header";
import { useEffect } from "react";
import { useDispatch, useSelector } from "@/model/store";
import { actions, selectors } from "@/model/slices/accounts";

export const LayoutMain = tix(
  {
    name: "LayoutMain",
    base: tw`p-4`,
    variants: {},
  },
  "div",
  (styled) => (props, ref) => {
    const [El, _props] = styled(props);

    const nav = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.accounts.token);
    const isExpired = useSelector(selectors.isExpired());
    useEffect(() => {
      if (isExpired) {
        dispatch(actions.doUserLogout({}));
      }
      !token ? nav("/login") : dispatch(actions.doGetProfile({}));
    }, []);

    return (
      <El {..._props} ref={ref} className="light text-foreground bg-background min-h-screen">
        <Header />
        <main className="container px-4 m-auto xl:px-0">
          <Outlet />
        </main>
      </El>
    );
  }
);
