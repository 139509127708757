import { Route, Routes } from "react-router-dom";

import { LayoutMain } from "@/layouts/LayoutMain";
// import { Home } from "./pages/Home";
import { Templates } from "./pages/Templates";
import { Messages } from "./pages/Messages";
import { Passes } from "./pages/Passes";
import { Login } from "./pages/Login";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutMain />}>
        <Route index element={<Templates />} />
        <Route index path="/templates" element={<Templates />}></Route>
        <Route path="/messages" element={<Messages />}></Route>
        <Route path="/passes" element={<Passes />}></Route>
      </Route>
      <Route path="/login" element={<Login />}></Route>
    </Routes>
  );
};
