import { tix, tw, withProps } from "@/libs/tix";
import { Button } from "../Button";
import { useDispatch, useSelector } from "@/model/store";
import { useCallback, useEffect, useState } from "react";
import {
  actions as templateAct,
  fieldWithParams,
  ITemplate,
  selectors as templateSel,
  extractKeys,
} from "@/model/slices/templates";
import { actions as passAct, IPass, IPassCreate } from "@/model/slices/passes";
import { toast } from "sonner";
import { Card, CardBody, CardFooter, CardHeader, Divider, Input, Select, SelectItem, Spacer } from "@nextui-org/react";

export interface PassDialogProps {
  close: () => void;
  passId?: number;
}

export const PassDialog = withProps<PassDialogProps>(tix)(
  {
    name: "PassDialog",
    base: tw`w-screen md:w-[600px] xl:w-[800px]`,
    variants: {},
  },
  "div",
  (styled) => (props, ref) => {
    const dispath = useDispatch();
    const templates = useSelector(templateSel.templates());
    const [selectedTemp, setSelectedTemp] = useState<ITemplate | null>(null);
    const [pass, setPass] = useState<IPass>({
      ID: 0,
      paramValues: {},
      passTemplateID: 0,
      serial: "",
      tagIDs: [],
      tags: [],
    });
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
      dispath(templateAct.listTemplates({}));
      // TODO in-case if passId is provided, fetch pass data and set it to pass
    }, []);

    const onSelectTemplate = useCallback(
      (templateId: number) => {
        const _template = templates.find((t) => t.ID === templateId) || null;
        setSelectedTemp(_template);
        setPass({
          ...pass,
          passTemplateID: templateId,
        });
      },
      [templates]
    );

    const onChangeParam = useCallback(
      (param: string, value: string) => {
        pass.paramValues[param] = value;
      },
      [selectedTemp]
    );

    const onCreatePass = useCallback(() => {
      const passCreate: IPassCreate = {
        passTemplateID: pass.passTemplateID,
        paramValues: pass.paramValues,
      };
      setLoading(true);
      dispath(passAct.createPass(passCreate))
        .unwrap()
        .then(() => props.close())
        .catch((err) => toast.error(`Failed to create pass ${err.message}`))
        .finally(() => setLoading(false));
    }, [pass]);

    const [El, { close, ..._props }] = styled(props);
    return (
      <El {..._props} ref={ref}>
        <Card radius="sm">
          <CardHeader className="text-primary-600 text-large">Create Pass</CardHeader>
          <Divider />
          <CardBody>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <Select
                size="sm"
                radius="sm"
                items={templates}
                aria-label="Select Template"
                label="Template"
                isRequired
                disabled={pass.ID !== 0}
                // classNames={{ base: tw`w-1/2` }}
                onChange={(ev) => (ev.target.value ? onSelectTemplate(parseInt(ev.target.value)) : null)}
              >
                {(template) => (
                  <SelectItem key={template.ID} value={template.ID}>
                    {template.description}
                  </SelectItem>
                )}
              </Select>
              {pass.ID !== 0 ? (
                <Input
                  placeholder="Serial"
                  size="md"
                  radius="sm"
                  disabled
                  className="flex-1"
                  classNames={{ inputWrapper: tw`h-full` }}
                  value={pass?.serial}
                />
              ) : null}
            </div>
            <Spacer y={4} />
            {selectedTemp ? (
              <>
                <div className="mt-4">
                  <div className="text-left w-full">
                    <div className="text-medium text-primary-500">QR CODE</div>
                    <div className="text-tiny text-primary-300 italic">{selectedTemp.qrCodeValue}</div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 my-3">
                    {extractKeys(selectedTemp.qrCodeValue).map((param, index) => (
                      // <div key={index}>{param}</div>
                      <Input
                        size="sm"
                        radius="sm"
                        key={index}
                        label={param}
                        onValueChange={(val) => onChangeParam(param, val)}
                      ></Input>
                    ))}
                  </div>
                </div>
                {fieldWithParams(selectedTemp, "vi").map((f) => (
                  <div key={f.key} className="mt-4">
                    <div className="text-left w-full">
                      <div className="text-medium text-primary-500">{f.languages.vi?.label || "No label"}</div>
                      <div className="text-tiny text-primary-300 italic">{f.languages.vi?.value}</div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 py-3">
                      {f.languages.vi?.parsedParams.map((param, index) => (
                        // <div key={index}>{param}</div>
                        <Input
                          size="sm"
                          radius="sm"
                          key={index}
                          label={param}
                          onValueChange={(val) => onChangeParam(param, val)}
                        ></Input>
                      ))}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              []
            )}
          </CardBody>
          <Divider />
          <CardFooter className="flex justify-end">
            <Button color="primary" isLoading={isLoading} onClick={onCreatePass}>
              Create
            </Button>
          </CardFooter>
        </Card>
      </El>
    );
  }
);
