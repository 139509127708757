import { tix, withProps } from "@/libs/tix";
import { useEffect, useState } from "react";
import { Input } from "@nextui-org/react";
import { ColorPickerDialog } from "../ColorPicker";
import { parseColor } from "react-aria-components";

export interface InputColorPickerProps {
  label: string;
  value?: string;
  onColorChange?: (colorRgb: string) => void;
}

export const InputColorPicker = withProps<InputColorPickerProps>(tix)(
  {
    name: "InputColorPicker",
    variants: {},
  },
  "div",
  (styled) => (props, ref) => {
    const [El, { value, onColorChange, ..._props }] = styled(props);
    const [color, setColor] = useState(parseColor(value || "#FFFFFF"));

    useEffect(() => {
      setColor(parseColor(value || "#FFFFFF"));
    }, [props.value]);

    return (
      <El {..._props} ref={ref}>
        <div className="flex flex-row gap-2 h-full justify-between items-center">
          <Input
            size="sm"
            radius="sm"
            type="text"
            label={props.label}
            className="items-center"
            value={color.toString("rgb")}
            endContent={
              <ColorPickerDialog
                defaultColor={color.toString("hex")}
                onColorChange={(val) => {
                  if (!value) setColor(val);
                  onColorChange?.(val.toString("rgb"));
                }}
              ></ColorPickerDialog>
            }
          />
        </div>
      </El>
    );
  }
);
