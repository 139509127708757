import { useCallback, useEffect, useMemo, useState } from "react";
import { groupBy } from "remeda";
import { FaPlus } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { AiOutlineNotification } from "react-icons/ai";
import { tix, tw } from "@/libs/tix";

import { useDispatch, useSelector } from "@/model/store";
import { actions, selectors } from "@/model/slices/templates";
import * as eventlogs from "@/model/slices/eventlogs";
import * as passes from "@/model/slices/passes";

import * as cps from "@/components";
// import * as bls from "@/components/blocks";

import {
  getKeyValue,
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";

const TemplateColumns = [
  {
    key: "id",
    label: "ID",
    sort: true,
  },
  {
    key: "description",
    label: "Description",
  },
  {
    key: "orgName",
    label: "Organization",
  },
  {
    key: "foregroundColor",
    label: "Foreground Color",
  },
  {
    key: "backgroundColor",
    label: "Background Color",
  },
  {
    key: "labelColor",
    label: "Label Color",
  },
  {
    key: "qrCodeValue",
    label: "QR Code Value",
  },
  {
    key: "qrCodeType",
    label: "QR Code Type",
  },
  {
    key: "serial",
    label: "Serial",
  },
  {
    key: "totalPasses",
    label: "Added / Total Passes",
  },
  {
    key: "action",
    label: "",
  },
];

enum Dialogs {
  TEMPLATE_CREATE_UPDATE = "dialog-template-create-update",
  PUSH_MESSAGE = "dialog-push-message",
}

export const Templates = tix(
  {
    name: "PageTemplate",
    base: tw`py-8`,
    variants: {},
  },
  "div",
  (styled) => (props, ref) => {
    const dispatch = useDispatch();
    const templates = useSelector(selectors.templates());

    const allEventLogs = useSelector(eventlogs.selectors.all());
    const allPasses = useSelector(passes.selectors.passes());
    const allPassWithLogs = allPasses.map((pass) => {
      const eventLog = allEventLogs.toReversed().find((eventLog) => eventLog.serial === pass.serial);
      return {
        ...pass,
        lastEventLog: eventLog,
      };
    });

    const [sorting, setSorting] = useState<SortDescriptor | null>(null);
    const { dialogs } = cps.useMyDialogs();

    const fetchTemplates = useCallback(({ sortOp }: { sortOp?: SortDescriptor }) => {
      let sort = "id:desc";
      if (sortOp) {
        sort = `${sortOp.column}:${sortOp.direction === "ascending" ? "asc" : "desc"}`;
      }
      dispatch(actions.listTemplates({ sort: sort }));
    }, []);

    useEffect(() => {
      fetchTemplates({});
      dispatch(eventlogs.actions.doGetEventlogs({}));
      dispatch(passes.actions.listPasses({}));
    }, []);

    const passesByTmpl = groupBy(allPassWithLogs, (pass) => pass.passTemplateID);

    const totalPasses = (templateId: number) => {
      const passes = passesByTmpl[templateId] || [];
      const added = passes.filter(
        (pass) => pass.lastEventLog?.type === "add" || pass.lastEventLog?.type === "refresh"
      ).length;
      return `${added} / ${passes.length}`;
    };

    const rows = useMemo(() => {
      return templates.map((template) => {
        return {
          id: template.ID,
          description: template.description,
          orgName: template.orgName,
          foregroundColor: template.foregroundColor,
          backgroundColor: template.backgroundColor,
          labelColor: template.labelColor,
          qrCodeValue: template.qrCodeValue,
          qrCodeType: template.qrCodeType,
          serial: template.serial,
          totalPasses: totalPasses(template.ID),
          action: (
            <div className="inline">
              <div className="text-medium md:text-large flex gap-2">
                <span
                  className="cursor-pointer text-warning-300 hover:text-warning-500"
                  onClick={() => {
                    dialogs[Dialogs.TEMPLATE_CREATE_UPDATE]?.open({ templateId: template.ID });
                  }}
                >
                  <FaRegEdit />
                </span>
                <span
                  className="cursor-pointer text-warning-300 hover:text-warning-500"
                  onClick={() => {
                    console.log("open dialog send message");
                    dialogs[Dialogs.PUSH_MESSAGE]?.open({ templateId: template.ID });
                  }}
                >
                  <AiOutlineNotification />
                </span>
              </div>
              <div></div>
            </div>
          ),
        };
      });
    }, [templates]);

    const doSort = (descriptor: SortDescriptor) => {
      setSorting(descriptor);
      fetchTemplates(descriptor ? { sortOp: descriptor } : {});
    };

    const onCloseTemplateDialog = () => {
      fetchTemplates(sorting ? { sortOp: sorting } : {});
    };

    const [El, _props] = styled(props);
    return (
      <El {..._props} ref={ref}>
        <div>
          <cps.Dialog
            name={Dialogs.TEMPLATE_CREATE_UPDATE}
            render={(close, props) => (
              <cps.TemplateDialog
                close={() => {
                  close();
                  onCloseTemplateDialog();
                }}
                {...props}
              />
            )}
          ></cps.Dialog>
          <cps.Dialog
            name={Dialogs.PUSH_MESSAGE}
            render={(close, props) => <cps.MessageDialog close={close} {...props} />}
          ></cps.Dialog>
          <div className="flex justify-between">
            <h1 className="text-2xl text-primary-300 font-semibold">Pass Templates</h1>
            <cps.Button
              variant="bordered"
              color="primary"
              startContent={<FaPlus />}
              onClick={() => {
                dialogs[Dialogs.TEMPLATE_CREATE_UPDATE]?.open();
              }}
            >
              Add Template
            </cps.Button>
          </div>
          <div className="mt-5">
            <Table
              aria-label="Pass Templates"
              isStriped
              topContentPlacement="outside"
              bottomContentPlacement="outside"
              sortDescriptor={sorting || undefined}
              onSortChange={doSort}
            >
              <TableHeader columns={TemplateColumns}>
                {(col) => (
                  <TableColumn aria-label={col.key} key={col.key} allowsSorting={col.sort} className="text-small">
                    {col.label}
                  </TableColumn>
                )}
              </TableHeader>
              <TableBody items={rows} emptyContent={"No rows to display."}>
                {(row) => (
                  <TableRow className="h-10" key={row.id}>
                    {(colKey) => <TableCell>{getKeyValue(row, colKey)}</TableCell>}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </El>
    );
  }
);
