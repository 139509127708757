import { useCallback, useEffect, useMemo, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { tix, tw } from "@/libs/tix";

import { useDispatch, useSelector } from "@/model/store";
import { actions, selectors } from "@/model/slices/passes";
import * as eventlogs from "@/model/slices/eventlogs";
import {
  Divider,
  getKeyValue,
  SortDescriptor,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/react";

import * as cps from "@/components";
import QRCode from "react-qr-code";
import { downloadPassUrl } from "@/model/api";
// import * as bls from "@/components/blocks";

const PassColumns = [
  {
    key: "id",
    label: "ID",
    sort: true,
  },
  {
    key: "paramValues",
    label: "Param Values",
  },
  {
    key: "pass_template_id",
    label: "Pass Template ID",
    sort: true,
  },
  {
    key: "serial",
    label: "Serial",
  },
  {
    key: "status",
    label: "Status",
  },
  {
    key: "latestUpdate",
    label: "Latest Update",
  },
  {
    key: "actions",
    label: "",
  },
];

enum Dialogs {
  CREATE_PASS = "dialog-create-pass",
  DETAIL_PASS = "dialog-detail-pass",
  DOWNLOAD_PASS = "dialog-download-pass",
}

export const Passes = tix(
  {
    name: "PagePasses",
    base: tw`py-8`,
    variants: {},
  },
  "div",
  (styled) => (props, ref) => {
    const dispatch = useDispatch();
    const passes = useSelector(selectors.passes());

    const allEventLogs = useSelector(eventlogs.selectors.all());
    const allPassWithLogs = passes.map((pass) => {
      const eventLog = allEventLogs.toReversed().find((eventLog) => eventLog.serial === pass.serial);
      return {
        ...pass,
        lastEventLog: eventLog,
      };
    });

    const [sorting, setSorting] = useState<SortDescriptor | null>(null);
    const { dialogs } = cps.useMyDialogs();

    const fetchPasses = useCallback(({ sortOp }: { sortOp?: SortDescriptor }) => {
      let sort = "id:desc";
      if (sortOp) {
        sort = `${sortOp.column}:${sortOp.direction === "ascending" ? "asc" : "desc"}`;
      }
      dispatch(actions.listPasses({ sort }));
    }, []);

    useEffect(() => {
      fetchPasses({});
      dispatch(eventlogs.actions.doGetEventlogs({}));
    }, []);

    const rows = useMemo(
      () =>
        allPassWithLogs.map((pass) => {
          const eventTime = pass.lastEventLog ? new Date(pass.lastEventLog.createdAt) : undefined;
          const status = eventTime ? pass.lastEventLog?.type : "";
          const latestUpdate = eventTime ? eventTime.toString() : "";
          return {
            id: pass.ID,
            paramValues: JSON.stringify(pass.paramValues),
            pass_template_id: pass.passTemplateID,
            serial: pass.serial,
            status,
            latestUpdate,
            actions: (
              <div className="text-medium md:text-large">
                <span
                  className="cursor-pointer text-secondary-300 hover:text-secondary-500"
                  onClick={() => {
                    dialogs[Dialogs.DOWNLOAD_PASS]?.open({ serial: pass.serial });
                  }}
                >
                  <FaCloudDownloadAlt />
                </span>
              </div>
            ),
          };
        }),
      [passes]
    );

    const doSort = (descriptor: SortDescriptor) => {
      setSorting(descriptor);
      fetchPasses({ sortOp: descriptor });
    };

    const onClosePassDialog = () => {
      fetchPasses(sorting ? { sortOp: sorting } : {});
    };

    const [El, _props] = styled(props);
    return (
      <El {..._props} ref={ref}>
        <Wrapper>
          <div>
            <cps.Dialog
              name={Dialogs.CREATE_PASS}
              render={(close, props) => (
                <cps.PassDialog
                  close={() => {
                    close();
                    onClosePassDialog();
                  }}
                  {...props}
                />
              )}
            ></cps.Dialog>
            <cps.Dialog
              name={Dialogs.DOWNLOAD_PASS}
              render={(close, { serial, ...props }) => (
                <cps.Card>
                  <cps.CardBody>
                    <div className="text-center">
                      <div className="text-center">
                        <h1 className="text-large text-primary-300 font-semibold">Scan to download Pass</h1>
                      </div>
                      <div className="mt-4">
                        <QRCode
                          size={256}
                          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                          value={downloadPassUrl(serial)}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                      <Divider className="my-4" />
                    </div>
                  </cps.CardBody>
                </cps.Card>
              )}
            ></cps.Dialog>
          </div>
          <div>
            <div className="flex justify-between">
              <h1 className="text-2xl text-primary-300 font-semibold">Passes</h1>
              <cps.Button
                variant="bordered"
                color="primary"
                startContent={<FaPlus />}
                onClick={() => {
                  dialogs[Dialogs.CREATE_PASS]?.open();
                }}
              >
                Add Pass
              </cps.Button>
            </div>
            <div className="mt-5">
              <Table
                aria-label="Distributed Passes"
                isStriped
                topContentPlacement="outside"
                bottomContentPlacement="outside"
                sortDescriptor={sorting || undefined}
                onSortChange={doSort}
              >
                <TableHeader columns={PassColumns}>
                  {(col) => (
                    <TableColumn aria-label={col.key} key={col.key} className="text-small" allowsSorting={col.sort}>
                      {col.label}
                    </TableColumn>
                  )}
                </TableHeader>
                <TableBody items={rows} emptyContent={"No rows to display."}>
                  {(row) => (
                    <TableRow className="h-10" key={row.id}>
                      {(colKey) => <TableCell width={10}>{getKeyValue(row, colKey)}</TableCell>}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
        </Wrapper>
      </El>
    );
  }
);

const Wrapper = tix({ name: "PagePassesWrapper", base: tw`flex flex-col gap-4`, variants: {} }, "div");
