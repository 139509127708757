import { dotPrefixer, typedName } from "@/libs/types";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { client } from "../api";
import { rootSelector } from "@/model/store";

export const name = typedName("tags");
export const thunkName = dotPrefixer(name);

const LIST_TAGS = typedName("listTags");

export interface ITags {
  ID: number;
  tag: string;
}

export const actions = {
  [LIST_TAGS]: createAsyncThunk(thunkName(LIST_TAGS), async (_, { dispatch, rejectWithValue }) => {
    return client
      .get<{ data: ITags[] }>("/tags")
      .then((res) => {
        dispatch(setTags(res.data.data));
        return res;
      })
      .catch(rejectWithValue);
  }),
};

export const selectors = {
  tags: () => createSelector(rootSelector, (state) => state.tags.tags),
};

export const slice = createSlice({
  name: name,
  initialState: {
    tags: [] as ITags[],
  },
  reducers: {
    setTags: (state, action) => {
      state.tags = action.payload;
    },
  },
});

export const { setTags } = slice.actions;
export const mutates = slice.actions;
