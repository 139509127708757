import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { typedName, dotPrefixer } from "@/libs/types";

// import { client } from "@/model/api";
import { rootSelector, createSelector, RootState } from "@/model/store";
import { createAsyncThunk } from "@/model/thunk";
import { client } from "@/model/api";

export const name = typedName("messages");
export const thunkName = dotPrefixer(name);
([] as (keyof RootState)[]).indexOf(name); // validation correctness setup reducers

/**
 * ACTION TYPES
 */
const DO_GET_MESSAGE_OBJECTS = typedName("doGetMessageObjects");

/**
 * MODEL TYPES
 */
export interface MessageObject {
  ID: number;
  content: string;
  messages: Message[];
  passIDs: number[];
  passTemplateID: number;
  scheduleAt: string;
}

export interface Message {
  ID: number;
  createdAt: string;
  messageObjectID: number;
  passID: number;
  passTemplateID: number;
}

/**
 * ACTION Handlers
 */
export const actions = {
  [DO_GET_MESSAGE_OBJECTS]: createAsyncThunk(
    thunkName(DO_GET_MESSAGE_OBJECTS),
    async (payload: {}, { dispatch, rejectWithValue }) => {
      return client
        .get<{ data: MessageObject[] }>("/message-objects")
        .then((res) => {
          dispatch(mutates.setAll(res.data.data));
          return res;
        })
        .catch(rejectWithValue);
    }
  ),
};

/**
 * QUERY Selectors
 */
export const selectors = {
  all: () =>
    createSelector(rootSelector, (state) => {
      return state.messages.all;
    }),
};

export const slice = createSlice({
  name: name,
  initialState: {
    all: [] as MessageObject[],
  },
  reducers: {
    setAll: (state, action: PayloadAction<MessageObject[]>) => {
      state.all = action.payload;
    },
  },
});

export const mutates = slice.actions;
