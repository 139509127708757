import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { typedName, dotPrefixer } from "@/libs/types";

// import { client } from "@/model/api";
import { rootSelector, RootState } from "@/model/store";
import { createAsyncThunk } from "@/model/thunk";
import { client } from "../api";

export const name = typedName("slice/templates");
export const thunkName = dotPrefixer(name);
name as keyof RootState; // validation correctness setup reducers

/**
 * ACTION TYPES
 */
const LIST_TEMPLATES = typedName("listTemplates");
const GET_TEMPLATE_BY_ID = typedName("getTemplateById");
const CREATE_TEMPLATE = typedName("createTemplate");
const UPDATE_TEMPLATE = typedName("updateTemplate");
const UPLOAD_TEMPLATE_FILE = typedName("uploadTemplateFile");
const CREATE_MESSAGE = typedName("createMessage");

/**
 * MODEL TYPES
 */
export enum FieldPosition {
  TITLE = "TITLE",
  HEAD = "HEAD",
  MAIN = "MAIN",
  SUB = "SUB",
  DETAILS = "DETAILS",
  // PUSH_MSG = "PUSH_MSG", // ? should allow use edit this field ?
}

export type Language = "en" | "vi";
export interface ILanguageValue {
  label: string;
  value: string;
  parsedParams: string[];
}

export interface ITemplateField {
  key: string;
  languages: Partial<Record<Language, ILanguageValue>>;
  position: FieldPosition;
}

export interface ITemplate {
  ID: number;
  description: string;
  orgName: string;
  style: string;
  foregroundColor: string;
  backgroundColor: string;
  labelColor: string;
  qrCodeValue: string;
  qrCodeType: string;
  serial: string;
  fields: ITemplateField[];
  tagIDs: number[];
  tags: string[];
}
export interface ITemplateCreate extends Omit<ITemplate, "ID" | "serial" | "tags"> {}
export interface ITemplateUpdate extends Omit<ITemplate, "tags"> {}

export interface IDefinedTemplate extends Omit<ITemplate, "tags"> {
  previewImg: string;
}

export interface ICreateMessage {
  content: string;
  passTemplateID: number;
  passIDS: number[];
  scheduleAt: Date | null;
}

export const extractKeys = (str: string) => {
  const regex = /\[\[ [^\]]*\.([a-zA-Z0-9_]+) [^\]]*\]\]/g;
  const res: string[] = [];
  for (const m of Array.from(str.matchAll(regex))) {
    if (m.length > 1 && m[1] && !res.includes(m[1]) && !["_serial", "_id", "_push_msg"].includes(m[1])) {
      res.push(m[1]);
    }
  }
  return res;
};

export const fieldWithParams = (template: ITemplate, lang: Language) => {
  const res: ITemplateField[] = [];
  for (const f of template.fields) {
    if (f.position == FieldPosition.HEAD) {
      continue;
    }
    if (!f.languages[lang] || !f.languages[lang].value) {
      continue;
    }

    const keys = extractKeys(f.languages[lang].value);
    if (keys.length > 0) {
      const _f = {
        ...f,
        languages: {
          ...f.languages,
          [lang]: {
            label: f.languages[lang].label,
            value: f.languages[lang].value,
            parsedParams: keys,
          },
        },
      };
      res.push(_f);
    }
  }
  return res;
};

export const getEditableFields = (template: ITemplate): ITemplateField[] => {
  return template.fields.filter((f) => Object.values(FieldPosition).includes(f.position));
};

import template1 from "@/assets/imgs/template1.png";
const PreDefinedTemplates: IDefinedTemplate[] = [
  {
    ID: 1,
    description: "Default",
    orgName: "Default",
    style: "default",
    foregroundColor: "rgb(255, 91, 3)",
    backgroundColor: "rgb(234, 26, 237)",
    labelColor: "rgb(176, 72, 72)",
    qrCodeValue: "",
    qrCodeType: "QR",
    serial: "000000",
    previewImg: template1,
    fields: [
      {
        key: "title",
        languages: {
          en: {
            label: "Title",
            value: "[[ .title ]]",
            parsedParams: [],
          },
          vi: {
            label: "Tiêu đề",
            value: "[[ .title ]]",
            parsedParams: [],
          },
        },
        position: FieldPosition.TITLE,
      },
      {
        key: "head",
        languages: {
          en: {
            label: "Head",
            value: "[[ .head ]]",
            parsedParams: [],
          },
          vi: {
            label: "Head",
            value: "[[ .head ]]",
            parsedParams: [],
          },
        },
        position: FieldPosition.HEAD,
      },
      {
        key: "main",
        languages: {
          en: {
            label: "Main",
            value: "[[ .main ]]",
            parsedParams: [],
          },
          vi: {
            label: "Main",
            value: "[[ .main ]]",
            parsedParams: ["main"],
          },
        },
        position: FieldPosition.MAIN,
      },
      {
        key: "sub",
        languages: {
          en: {
            label: "Sub",
            value: "[[ .sub ]]",
            parsedParams: [],
          },
          vi: {
            label: "Sub",
            value: "[[ .sub ]]",
            parsedParams: [],
          },
        },
        position: FieldPosition.SUB,
      },
      {
        key: "details",
        languages: {
          en: {
            label: "Details",
            value: "[[ .details ]]",
            parsedParams: [],
          },
          vi: {
            label: "Details",
            value: "[[ .details ]]",
            parsedParams: [],
          },
        },
        position: FieldPosition.DETAILS,
      },
    ],
    tagIDs: [],
  },
];

/**
 * ACTION Handlers
 */
export const actions = {
  [LIST_TEMPLATES]: createAsyncThunk(
    thunkName(LIST_TEMPLATES),
    async ({ sort }: { sort?: string }, { dispatch, rejectWithValue }) => {
      let params = {}; // * query params
      if (sort) {
        params = { sort };
      }
      return client
        .get<{ data: ITemplate[] }>("/pass-templates", { params: params })
        .then((res) => {
          dispatch(setTemplates(res.data.data));
          return res;
        })
        .catch(rejectWithValue);
    }
  ),
  [GET_TEMPLATE_BY_ID]: createAsyncThunk(thunkName(GET_TEMPLATE_BY_ID), async (id: number, { rejectWithValue }) => {
    return client
      .get<ITemplate>(`/pass-templates/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch(rejectWithValue);
  }),
  [CREATE_TEMPLATE]: createAsyncThunk(
    thunkName(CREATE_TEMPLATE),
    async (data: ITemplateCreate, { rejectWithValue }) => {
      return client
        .post<ITemplate>("/pass-templates", data)
        .then((res) => {
          return res.data;
        })
        .catch(rejectWithValue);
    }
  ),
  [UPDATE_TEMPLATE]: createAsyncThunk(
    thunkName(UPDATE_TEMPLATE),
    async (data: ITemplateUpdate, { rejectWithValue }) => {
      return client
        .put<ITemplate>(`/pass-templates/${data.ID}`, data)
        .then((res) => res.data)
        .catch(rejectWithValue);
    }
  ),
  [UPLOAD_TEMPLATE_FILE]: createAsyncThunk(
    thunkName(UPLOAD_TEMPLATE_FILE),
    async ({ templateId, data }: { templateId: number; data: FormData }, { rejectWithValue }) => {
      // console.log("cai gi day", templateId, data.entries());
      return client
        .post<ITemplate, any, FormData>(`/pass-templates/${templateId}/assets`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => res.data)
        .catch(rejectWithValue);
    }
  ),
  [CREATE_MESSAGE]: createAsyncThunk(thunkName(CREATE_MESSAGE), async (data: ICreateMessage, { rejectWithValue }) => {
    return client
      .post(`/pass-templates/${data.passTemplateID}/messages`, data)
      .then((res) => res.data)
      .catch(rejectWithValue);
  }),
};

/**
 * QUERY Selectors
 */
export const selectors = {
  definedTemplates: () => createSelector(rootSelector, (state) => state["slice/templates"].definedTemplates),
  templates: () =>
    createSelector(rootSelector, (state) => {
      return state["slice/templates"].templates;
    }),
};

export const slice = createSlice({
  name: name,
  initialState: {
    definedTemplates: PreDefinedTemplates,
    templates: [] as ITemplate[],
  },
  reducers: {
    setTemplates: (state, action: PayloadAction<ITemplate[]>) => {
      state.templates = action.payload;
    },
  },
});

export const { setTemplates } = slice.actions;

export const mutates = slice.actions;
