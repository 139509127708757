import { tix, tw } from "@/libs/tix";
import { Button } from "../Button";
import { ImInsertTemplate, ImTicket } from "react-icons/im";
import { FaChevronDown } from "react-icons/fa6";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AiOutlineNotification } from "react-icons/ai";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from "@nextui-org/react";
import { useSelector } from "@/model/store";
import { selectors } from "@/model/slices/accounts";

export const Header = tix(
  {
    name: "Header",
    base: tw``,
    variants: {},
  },
  "div",
  (styled) => (props, ref) => {
    const [El, _props] = styled(props);
    const nav = useNavigate();

    const isLogged = useSelector(selectors.isLogged());
    const profile = useSelector((state) => state.accounts.profile);

    const navigate = (path: string) => {
      console.log("Navigate");
      nav(path);
    };

    return (
      <El {..._props} ref={ref}>
        <Navbar classNames={{ base: tw`container m-auto`, wrapper: tw`md:px-0 max-w-none` }}>
          <NavbarBrand as={Link} to="/">
            {/* <AcmeLogo /> */}
            <p className="font-bold text-2xl text-primary-500">1Passes</p>
          </NavbarBrand>
          <NavbarContent className="hidden sm:flex gap-4" justify="center">
            <Dropdown>
              <NavbarItem>
                <DropdownTrigger>
                  <Button
                    disableRipple
                    className="p-0 bg-transparent data-[hover=true]:bg-transparent"
                    endContent={<FaChevronDown />}
                    radius="sm"
                    variant="light"
                  >
                    Features
                  </Button>
                </DropdownTrigger>
              </NavbarItem>
              <DropdownMenu
                aria-label="1Passes features"
                className="max-w-[340px]"
                itemClasses={{
                  base: "gap-4",
                }}
              >
                <DropdownItem
                  key="templates"
                  description="Passes Templates."
                  startContent={<ImInsertTemplate />}
                  onClick={() => navigate("/templates")}
                >
                  Passes Templates
                </DropdownItem>
                <DropdownItem
                  key="passes"
                  description="Passes distributed."
                  startContent={<ImTicket />}
                  onClick={() => navigate("/passes")}
                >
                  Passes
                </DropdownItem>
                <DropdownItem
                  key="messages"
                  description="Messages"
                  startContent={<AiOutlineNotification />}
                  onClick={() => navigate("/messages")}
                >
                  Messages
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {/* <NavbarItem>
              <NavLink to="/passes">MENU HOLDER</NavLink>
            </NavbarItem>
            <NavbarItem>
              <NavLink to="/#">MENU HOLDER</NavLink>
            </NavbarItem> */}
          </NavbarContent>
          <NavbarContent justify="end">
            {isLogged ? (
              <div className="text-small">Hi, {profile?.email || ""}</div>
            ) : (
              <>
                <NavbarItem className="hidden lg:flex">
                  <Link to="#">Login</Link>
                </NavbarItem>
                <NavbarItem>
                  <Button as={Link} color="primary" to="#" variant="flat">
                    Sign Up
                  </Button>
                </NavbarItem>
              </>
            )}
          </NavbarContent>
        </Navbar>
      </El>
    );
  }
);
